<template>
  <v-dialog v-model="open" width="500">
    <v-card>
      <v-card-title class="headline">{{$t('temp_login.title')}}</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            autocomplete="off"
            :label="$t('fields.username_or_mail')"
            :readonly="state.loading"
            :rules="[rules.required]"
            :error="loginError"
            v-model="credentials.user"
            class="mb-4"
            outlined
            hide-details />
          <v-text-field
            autocomplete="off"
            :label="$t('fields.password')"
            :readonly="state.loading"
            v-on:keyup.enter.prevent="signIn()"
            :rules="[rules.required]"
            :error="loginError"
            v-model="credentials.password"
            @click:append="show = !show"
            :type="show ? 'text' : 'password'"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            outlined
            hide-details
            class="mb-2" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-layout column>
          <v-layout row justify-end class="ma-0">
            <v-btn text color="primary" @click="open = false" class="mt-3">{{$t('actions.cancel')}}</v-btn>
            <v-btn :loading="state.loading" outlined color="primary" @click="signIn()" class="mt-3">{{$t('actions.continue')}}</v-btn>
          </v-layout>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ShiftRequests } from '@/services/shift.requests'
import { mapState } from 'vuex'
import { operatorRoles } from '@/config'
const shiftService = new ShiftRequests()
export default {
  props: {
    triggerOpen: Date
  },
  data () {
    return {
      show: false,
      open: false,
      credentials: {
        user: null,
        password: null
      },
      loginError: null,
      rules: {
        required: value => !!value || ''
      }
    }
  },
  computed: {
    ...mapState(['state'])
  },
  methods: {
    signIn () {
      if (this.$refs.form.validate()) {
        this.loginError = false
        this.$store.dispatch('auth/signIn', { user: this.credentials.user, password: this.credentials.password, temp: true }).then(() => {
          shiftService.getOperatorShift({ useTemporalToken: true }).then((res) => {
            if (res.role !== operatorRoles.SUPERVISOR) {
              this.loginError = true
            } else {
              this.$emit('finished')
              this.open = false
            }
          })
        }).catch((error) => {
          this.loginError = true
          if (error && error.body && error.body.message === 'Operator is not active') {
            this.$store.commit('state/setMessage', { text: this.$t('error.auth.disabled_operator'), color: 'error' })
          }
        })
      }
    }
  },
  watch: {
    triggerOpen () {
      this.open = true
    },
    open () {
      if (!this.open) {
        this.credentials.user = null
        this.credentials.password = null
        this.loginError = null
        this.$refs.form.reset()
      }
    }
  }
}
</script>
